"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const CardRenderItemType_1 = require("@/common/cards/render/CardRenderItemType");
const AltSecondaryTag_1 = require("@/common/cards/render/AltSecondaryTag");
const Size_1 = require("@/common/cards/render/Size");
const Types_1 = require("@/common/cards/render/Types");
const RESOURCE_AND_TAG_TYPES = [
    CardRenderItemType_1.CardRenderItemType.ANIMALS,
    CardRenderItemType_1.CardRenderItemType.PLANTS,
    CardRenderItemType_1.CardRenderItemType.MICROBES,
    CardRenderItemType_1.CardRenderItemType.SCIENCE,
    CardRenderItemType_1.CardRenderItemType.CITY
];
exports.default = vue_1.default.extend({
    name: 'CardRenderItemComponent',
    props: {
        item: {
            type: Object,
        },
    },
    methods: {
        sized(clazz, size) {
            return size !== undefined ? `${clazz}--${size}` : clazz;
        },
    },
    computed: {
        cardResource() {
            return this.item.secondaryTag !== undefined ? 'card-resource--has-secondary-tag' : 'card-resource';
        },
        componentClasses() {
            let classes = [];
            switch (this.item.type) {
                case CardRenderItemType_1.CardRenderItemType.TEMPERATURE:
                    classes.push('card-global-requirement', 'card-temperature-global-requirement');
                    break;
                case CardRenderItemType_1.CardRenderItemType.OXYGEN:
                    classes.push('card-global-requirement', 'card-oxygen-global-requirement');
                    break;
                case CardRenderItemType_1.CardRenderItemType.OCEANS:
                    classes.push('card-global-requirement', 'card-ocean-global-requirement');
                    if (this.item.size !== undefined && this.item.size !== Size_1.Size.MEDIUM) {
                        classes.push(`card-ocean--${this.item.size}`);
                    }
                    break;
                case CardRenderItemType_1.CardRenderItemType.VENUS:
                    classes.push('card-global-requirement', 'card-venus-global-requirement');
                    break;
                case CardRenderItemType_1.CardRenderItemType.TR:
                    classes.push('card-tile', 'card-tr');
                    if (this.item.size !== undefined && this.item.size !== Size_1.Size.MEDIUM) {
                        classes.push(`card-tr--${this.item.size}`);
                    }
                    break;
                case CardRenderItemType_1.CardRenderItemType.TITANIUM:
                    classes.push(this.cardResource, 'card-resource-titanium');
                    break;
                case CardRenderItemType_1.CardRenderItemType.STEEL:
                    classes.push(this.cardResource, 'card-resource-steel');
                    break;
                case CardRenderItemType_1.CardRenderItemType.HEAT:
                    classes.push(this.cardResource, 'card-resource-heat');
                    break;
                case CardRenderItemType_1.CardRenderItemType.ENERGY:
                    classes.push(this.cardResource, 'card-resource-energy');
                    break;
                case CardRenderItemType_1.CardRenderItemType.PLANTS:
                    classes.push(this.cardResource, 'card-resource-plant');
                    break;
                case CardRenderItemType_1.CardRenderItemType.MEGACREDITS:
                    classes.push(this.cardResource, 'card-resource-money');
                    if (this.item.size !== undefined && this.item.size !== Size_1.Size.MEDIUM) {
                        classes.push(`card-money--${this.item.size}`);
                    }
                    break;
                case CardRenderItemType_1.CardRenderItemType.CARDS:
                    classes.push(this.cardResource, 'card-card');
                    break;
                case CardRenderItemType_1.CardRenderItemType.FLOATERS:
                    classes.push(this.cardResource, 'card-resource-floater');
                    break;
                case CardRenderItemType_1.CardRenderItemType.ASTEROIDS:
                    classes.push(this.cardResource, 'card-resource-asteroid');
                    break;
                case CardRenderItemType_1.CardRenderItemType.MICROBES:
                    classes.push(this.cardResource, 'card-resource-microbe');
                    break;
                case CardRenderItemType_1.CardRenderItemType.ANIMALS:
                    classes.push(this.cardResource, 'card-resource-animal');
                    break;
                case CardRenderItemType_1.CardRenderItemType.WILD:
                    classes.push(this.cardResource, 'card-resource-wild');
                    if (this.item.cancelled === true) {
                        classes.push('card-private-security');
                    }
                    break;
                case CardRenderItemType_1.CardRenderItemType.PRESERVATION:
                    classes.push(this.cardResource, 'card-resource-preservation');
                    break;
                case CardRenderItemType_1.CardRenderItemType.FIGHTER:
                    classes.push(this.cardResource, 'card-resource-fighter');
                    break;
                case CardRenderItemType_1.CardRenderItemType.CAMPS:
                    classes.push(this.cardResource, 'card-resource-camp');
                    break;
                case CardRenderItemType_1.CardRenderItemType.DIVERSE_TAG:
                    classes.push(this.cardResource, 'card-resource-diverse');
                    break;
                case CardRenderItemType_1.CardRenderItemType.SCIENCE:
                    classes.push(this.cardResource, 'card-resource-science');
                    break;
                case CardRenderItemType_1.CardRenderItemType.TRADE:
                    classes.push('card-resource-trade');
                    if (this.item.size === Size_1.Size.SMALL) {
                        classes.push('card-resource-colony--S');
                    }
                    break;
                case CardRenderItemType_1.CardRenderItemType.COLONIES:
                    classes.push('card-resource-colony');
                    if (this.item.size === Size_1.Size.SMALL) {
                        classes.push('card-resource-colony--S');
                    }
                    break;
                case CardRenderItemType_1.CardRenderItemType.TRADE_DISCOUNT:
                case CardRenderItemType_1.CardRenderItemType.MULTIPLIER_WHITE:
                    classes.push(this.cardResource, 'card-resource-trade-discount');
                    break;
                case CardRenderItemType_1.CardRenderItemType.TRADE_FLEET:
                    classes.push('card-resource-trade-fleet');
                    break;
                case CardRenderItemType_1.CardRenderItemType.SYNDICATE_FLEET:
                    classes.push(this.cardResource, 'card-resource-syndicate-fleet');
                    break;
                case CardRenderItemType_1.CardRenderItemType.CHAIRMAN:
                    classes.push('card-chairman');
                    break;
                case CardRenderItemType_1.CardRenderItemType.PARTY_LEADERS:
                    classes.push('card-party-leader');
                    break;
                case CardRenderItemType_1.CardRenderItemType.DELEGATES:
                    classes.push('card-delegate');
                    break;
                case CardRenderItemType_1.CardRenderItemType.INFLUENCE:
                    classes.push('card-influence', `card-influence--size-${this.item.size}`);
                    break;
                case CardRenderItemType_1.CardRenderItemType.NO_TAGS:
                    classes.push('card-resource-tag', 'card-community-services');
                    break;
                case CardRenderItemType_1.CardRenderItemType.EMPTY_TAG:
                    classes.push('card-resource-tag', 'card-tag-empty');
                    break;
                case CardRenderItemType_1.CardRenderItemType.CITY:
                    if (this.item.isPlayed !== true) {
                        classes.push('card-tile', `city-tile--${this.item.size}`);
                    }
                    break;
                case CardRenderItemType_1.CardRenderItemType.GREENERY:
                    classes.push('card-tile');
                    if (this.item.secondaryTag === AltSecondaryTag_1.AltSecondaryTag.OXYGEN) {
                        classes.push(`greenery-tile-oxygen--${this.item.size}`);
                    }
                    else {
                        classes.push(`greenery-tile--${this.item.size}`);
                    }
                    break;
                case CardRenderItemType_1.CardRenderItemType.EMPTY_TILE:
                    classes.push('card-tile-ares');
                    if (this.item.size !== undefined) {
                        classes.push(`board-space-tile--empty-tile--${this.item.size}`);
                    }
                    break;
                case CardRenderItemType_1.CardRenderItemType.EMPTY_TILE_GOLDEN:
                    classes.push('card-tile-ares', 'board-space-tile--adjacency-tile');
                    break;
                case CardRenderItemType_1.CardRenderItemType.EMPTY_TILE_SPECIAL:
                    classes.push('card-tile');
                    if (this.item.size !== undefined) {
                        classes.push(`special-tile--${this.item.size}`);
                    }
                    else {
                        classes.push('special-tile');
                    }
                    break;
                case CardRenderItemType_1.CardRenderItemType.CITY_OR_SPECIAL_TILE:
                    classes.push('card-tile', 'city-or-special-tile');
                    break;
                case CardRenderItemType_1.CardRenderItemType.COMMUNITY:
                    classes.push(this.cardResource, 'card-resource-community');
                    break;
                case CardRenderItemType_1.CardRenderItemType.DISEASE:
                    classes.push(this.cardResource, 'card-resource-disease');
                    break;
                case CardRenderItemType_1.CardRenderItemType.DATA_RESOURCE:
                    classes.push(this.cardResource, 'card-resource-data');
                    break;
                case CardRenderItemType_1.CardRenderItemType.RESOURCE_CUBE:
                    classes.push(this.cardResource, 'card-resource-cube');
                    break;
                case CardRenderItemType_1.CardRenderItemType.VENUSIAN_HABITAT:
                    classes.push(this.cardResource, 'card-resource-venusian-habitat');
                    break;
                case CardRenderItemType_1.CardRenderItemType.SPECIALIZED_ROBOT:
                    classes.push(this.cardResource, 'card-resource-specialized-robot');
                    break;
                case CardRenderItemType_1.CardRenderItemType.SEED:
                    classes.push(this.cardResource, 'card-resource-seed');
                    break;
                case CardRenderItemType_1.CardRenderItemType.ORBITAL:
                    classes.push(this.cardResource, 'card-resource-orbital');
                    break;
                case CardRenderItemType_1.CardRenderItemType.AGENDA:
                    classes.push(this.cardResource, 'card-resource-agenda');
                    break;
                case CardRenderItemType_1.CardRenderItemType.MOON_HABITAT:
                    if (this.item.secondaryTag === AltSecondaryTag_1.AltSecondaryTag.MOON_HABITAT_RATE) {
                        classes.push(this.sized('card-tile-lunar-habitat-rate', this.item.size));
                    }
                    else {
                        classes.push(this.sized('card-tile-lunar-habitat', this.item.size));
                    }
                    break;
                case CardRenderItemType_1.CardRenderItemType.GLOBAL_EVENT:
                    classes.push('turmoil-global-event');
                    break;
                case CardRenderItemType_1.CardRenderItemType.ARROW_OPG:
                    classes.push('card-arrow-opg');
                    break;
                case CardRenderItemType_1.CardRenderItemType.REDS:
                    classes.push('card-reds');
                    break;
                case CardRenderItemType_1.CardRenderItemType.REDS_DEACTIVATED:
                    classes.push('card-reds-deactivated');
                    break;
                case CardRenderItemType_1.CardRenderItemType.ADJACENCY_BONUS:
                    classes.push('card-adjacency-bonus');
                    break;
                case CardRenderItemType_1.CardRenderItemType.HAZARD_TILE:
                    if (this.item.size !== undefined && this.item.size !== Size_1.Size.MEDIUM) {
                        classes.push(`card-hazard-tile--${this.item.size}`);
                    }
                    else {
                        classes.push('card-hazard-tile');
                    }
                    break;
                case CardRenderItemType_1.CardRenderItemType.CLONE_TROOPER:
                    classes.push(this.cardResource, 'card-resource-clone-trooper');
                    break;
                case CardRenderItemType_1.CardRenderItemType.MOON_HABITAT_RATE:
                    classes.push('card-colony-rate');
                    if (this.item.size !== undefined) {
                        classes.push(`card-colony-rate--${this.item.size}`);
                    }
                    break;
                case CardRenderItemType_1.CardRenderItemType.MOON_MINE:
                    if (this.item.secondaryTag === AltSecondaryTag_1.AltSecondaryTag.MOON_MINING_RATE) {
                        classes.push(this.sized('card-tile-lunar-mine-rate', this.item.size));
                    }
                    else {
                        classes.push(this.sized('card-tile-lunar-mine', this.item.size));
                    }
                    break;
                case CardRenderItemType_1.CardRenderItemType.MOON_MINING_RATE:
                    classes.push('card-mining-rate');
                    if (this.item.size !== undefined)
                        classes.push(`card-mining-rate--${this.item.size}`);
                    break;
                case CardRenderItemType_1.CardRenderItemType.MOON_ROAD:
                    if (this.item.secondaryTag === AltSecondaryTag_1.AltSecondaryTag.MOON_LOGISTICS_RATE) {
                        classes.push(this.sized('card-tile-lunar-road-rate', this.item.size));
                    }
                    else {
                        classes.push(this.sized('card-tile-lunar-road', this.item.size));
                    }
                    break;
                case CardRenderItemType_1.CardRenderItemType.MOON_LOGISTICS_RATE:
                    classes.push('card-logistics-rate');
                    if (this.item.size !== undefined)
                        classes.push(`card-logistics-rate--${this.item.size}`);
                    break;
                case CardRenderItemType_1.CardRenderItemType.PLANETARY_TRACK:
                    classes.push('card-planetary-track');
                    break;
                case CardRenderItemType_1.CardRenderItemType.CATHEDRAL:
                    classes.push(this.cardResource, 'card-resource-cathedral');
                    break;
                case CardRenderItemType_1.CardRenderItemType.GRAPHENE:
                    classes.push(this.cardResource, 'card-resource-graphene');
                    break;
                case CardRenderItemType_1.CardRenderItemType.NOMADS:
                    classes.push(this.cardResource, 'card-resource-nomads');
                    break;
                case CardRenderItemType_1.CardRenderItemType.HYDROELECTRIC_RESOURCE:
                    classes.push(this.cardResource, 'card-resource-hydroelectric-resource');
                    break;
                case CardRenderItemType_1.CardRenderItemType.IDENTIFY:
                    classes.push('card-identification');
                    break;
                case CardRenderItemType_1.CardRenderItemType.EXCAVATE:
                    classes.push('card-excavation');
                    break;
                case CardRenderItemType_1.CardRenderItemType.CORRUPTION:
                    classes.push(this.cardResource, 'card-resource-corruption');
                    break;
                case CardRenderItemType_1.CardRenderItemType.TOOL:
                    classes.push(this.cardResource, 'card-resource-tool');
                    break;
                case CardRenderItemType_1.CardRenderItemType.WARE:
                    classes.push(this.cardResource, 'card-resource-ware');
                    break;
                case CardRenderItemType_1.CardRenderItemType.SCOOP:
                    classes.push(this.cardResource, 'card-resource-scoop');
                    break;
                case CardRenderItemType_1.CardRenderItemType.JOURNALISM:
                    classes.push(this.cardResource, 'card-resource-journalism');
                    break;
                case CardRenderItemType_1.CardRenderItemType.ACTIVIST:
                    classes.push(this.cardResource, 'card-resource-activist');
                    break;
                case CardRenderItemType_1.CardRenderItemType.SUPPLY_CHAIN:
                    classes.push(this.cardResource, 'card-resource-supply-chain');
                    break;
                case CardRenderItemType_1.CardRenderItemType.NEUTRAL_DELEGATE:
                    classes.push('card-neutral-delegate');
                    break;
                case CardRenderItemType_1.CardRenderItemType.UNDERGROUND_RESOURCES:
                    classes.push('card-underground-resources');
                    break;
                case CardRenderItemType_1.CardRenderItemType.CORRUPTION_SHIELD:
                    classes.push('card-corruption-shield');
                    break;
                case CardRenderItemType_1.CardRenderItemType.GEOSCAN_ICON:
                    classes.push('card-geoscan-icon');
                    break;
            }
            if (this.item.secondaryTag === AltSecondaryTag_1.AltSecondaryTag.NO_PLANETARY_TAG) {
                classes.push('tag-clone');
            }
            const type = this.item.type;
            if (this.item.isPlayed) {
                if (RESOURCE_AND_TAG_TYPES.includes(type)) {
                    classes = classes.filter((c) => c !== this.cardResource);
                }
                classes.push('card-resource-tag');
                if (type === CardRenderItemType_1.CardRenderItemType.EVENT) {
                    classes.push('card-tag-event');
                }
                else if (type === CardRenderItemType_1.CardRenderItemType.SPACE) {
                    classes.push('card-tag-space');
                }
                else if (type === CardRenderItemType_1.CardRenderItemType.SCIENCE) {
                    classes.push('card-tag-science');
                }
                else if (type === CardRenderItemType_1.CardRenderItemType.JOVIAN) {
                    classes.push('card-tag-jovian');
                }
                else if (type === CardRenderItemType_1.CardRenderItemType.VENUS) {
                    classes.push('card-tag-venus');
                }
                else if (type === CardRenderItemType_1.CardRenderItemType.EARTH) {
                    classes.push('card-tag-earth');
                }
                else if (type === CardRenderItemType_1.CardRenderItemType.BUILDING) {
                    classes.push('card-tag-building');
                }
                else if (type === CardRenderItemType_1.CardRenderItemType.CITY) {
                    classes.push('card-tag tag-city');
                }
                else if (this.item.type === CardRenderItemType_1.CardRenderItemType.MARS) {
                    classes.push('card-tag tag-mars');
                }
            }
            if (this.item.anyPlayer === true) {
                if (type === CardRenderItemType_1.CardRenderItemType.DELEGATES) {
                    classes.push('card-delegate-red');
                }
                else if (type === CardRenderItemType_1.CardRenderItemType.CHAIRMAN) {
                    classes.push('card-chairman-red');
                }
                else {
                    classes.push('red-outline');
                }
            }
            if (this.item.isPlate) {
                classes.push('card-plate');
                if (this.item.size === Size_1.Size.SMALL) {
                    classes.push('card-plate--narrow');
                }
            }
            if (this.item.text !== undefined) {
                classes.push(`card-text-size--${this.item.size}`);
                if (this.item.isUppercase) {
                    classes.push('card-text-uppercase');
                }
                if (this.item.isBold) {
                    classes.push('card-text-bold');
                }
                else {
                    classes.push('card-text-normal');
                }
            }
            return classes;
        },
        amountAbs() {
            if (this.item.amountInside)
                return 1;
            return Math.abs(this.item.amount);
        },
        itemsToShow() {
            if (this.item.showDigit)
                return 1;
            return this.amountAbs;
        },
        itemHtmlContent() {
            let result = '';
            if ((0, Types_1.isICardRenderItem)(this.item) && this.item.amountInside) {
                if (this.item.questionMark === true) {
                    result += '?';
                }
                else if (this.item.amount !== 0) {
                    result += this.item.amount.toString();
                }
                if (this.item.multiplier) {
                    result += 'X';
                }
                if (this.item.clone) {
                    result += '<div style="-webkit-filter: greyscale(100%);filter: grayscale(100%)">🪐</div>';
                }
            }
            const previouslyRendered = [
                AltSecondaryTag_1.AltSecondaryTag.OXYGEN,
                AltSecondaryTag_1.AltSecondaryTag.MOON_HABITAT_RATE,
                AltSecondaryTag_1.AltSecondaryTag.MOON_MINING_RATE,
                AltSecondaryTag_1.AltSecondaryTag.MOON_LOGISTICS_RATE,
            ];
            const secondaryTag = this.item.secondaryTag;
            if (secondaryTag !== undefined && !previouslyRendered.includes(secondaryTag)) {
                result += '<div class="card-icon card-tag-' + secondaryTag + '"></div>';
            }
            if (this.item.isPlate || this.item.text !== undefined) {
                result += this.item.text || 'n/a';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.NO_TAGS || this.item.type === CardRenderItemType_1.CardRenderItemType.MULTIPLIER_WHITE) {
                result = 'X';
            }
            else if (this.item.type === CardRenderItemType_1.CardRenderItemType.IGNORE_GLOBAL_REQUIREMENTS) {
                result += '<div class="card-project-requirements">';
                result += '<div class="card-x">x</div>';
                result += '<div class="card-requirements">Global Requirements</div>';
                result += '</div>';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.SELF_REPLICATING) {
                result = '<div class="card-resource card-card"><div class="cards-count">2</div><div class="card-icon card-icon-space">✴</div><div class="card-icon card-icon-building">☗</div></div>';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.COLONY_TILE) {
                result = '<span class="card-colony-tile">colony</span>';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.PRELUDE) {
                result = '<div class="card-prelude-container"><span class="card-prelude-icon">prel</span></div>';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.CORPORATION) {
                result = '<div class="card-corporation-icon"></div>';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.FIRST_PLAYER) {
                result = '<div class="card-first-player-icon"></div>';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.RULING_PARTY) {
                result = '<div class="card-party-icon"></div>';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.AWARD) {
                result = '<span class="card-award-icon">award</span>';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.MILESTONE) {
                result = '<span class="card-award-icon">milestone</span>';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.VP) {
                result = '<div class="card-resource points-big card-vp-questionmark">?</div>';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.MEGACREDITS && this.item.amount === undefined) {
                result = '?';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.MOON) {
                return '<div class="card-tag-moon-on-card"></div>';
            }
            if (this.item.type === CardRenderItemType_1.CardRenderItemType.RESOURCE_CUBE) {
                return '<div class="board-cube--bronze"></div>';
            }
            if (this.item.cancelled === true) {
                switch (this.item.type) {
                    case CardRenderItemType_1.CardRenderItemType.TR:
                    case CardRenderItemType_1.CardRenderItemType.WILD:
                    case CardRenderItemType_1.CardRenderItemType.UNDERGROUND_RESOURCES:
                        result = '<div class="card-x">x</div>';
                }
            }
            return result;
        },
    },
});
